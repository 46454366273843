body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body * {
    box-sizing: border-box;
}

/* removes scroll bar  */
body::-webkit-scrollbar {
    /* display: none; */
}
